import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sidebar", "adminPrivilegeSwitchForm", "entityTabsInDom"];
  static values = { page: String, userTrustId: String };

  initialize() {
    // Initialize axios
    // this.axios = require('axios');
    // this.axios.defaults.xsrfCookieName = "CSRF-TOKEN";
    // this.axios.defaults.xsrfHeaderName = "X-CSRF-Token";
    // this.axios.defaults.withCredentials = true;
  }

  connect() {
    // Get the window width to determine the sidebar functioning.
    // This will be done on connect only.
    this.width = window.innerWidth;
    if (this.width < 768) {
      $(".content").addClass("sidebar-close");
      $(".sidebar").addClass("close");
      $(".sub").addClass("dropend");
      $(".parent-item-open-link").attr("data-bs-toggle", "dropdown");
      $(".parent-item-open-link ~ ul").removeClass("sub-menu");
      $(".parent-item-open-link ~ ul").addClass(
        "dropdown-menu dropdown-menu-end"
      );
    }

    // Set the content margin after the page (content) loads
    // document.addEventListener('turbo:load', () => {
    //   // Set content width
    //   this.setContentMargin();
    // });
  }

  // Handle switching of admin privilege
  adminPrivilegeSwitched() {
    // Set hidden field value depicting whether user is working within a tab (tab link clicked) or full page (sidebar link clicked)
    this.entityTabsInDomTarget.value =
      $(".entity-tabs").length > 0 ? "true" : "false";

    // Submit the adminPrivilegeSwitchForm
    this.adminPrivilegeSwitchFormTarget.requestSubmit();
  }

  // Highlight given sidebar link
  highlightLink(link) {
    // console.log("hightlight link", link);
    // Reset sidebar links
    $(".menu-links .nav-link, .dropdown-item , .subMenu").removeClass("active");
    // Mark link as active
    $(link).addClass("active");
    // If the link is a sub-item, expand it if collapsed
    if ($(link).closest(".subMenu").length > 0) {
      $(link).closest(".subMenu").addClass("active");
      // let parentItemOpenLink = $(link).closest(".parent-item").find(".parent-item-open-link");
      // // Open only if the parent item is collapsed
      // if ($(parentItemOpenLink).hasClass("collapsed")) {
      //   // Mark the parent item as expanded
      //   $(parentItemOpenLink).removeClass("collapsed");
      //   // Mark the sub-menu as showing
      //   $(link).closest(".collapse").addClass("show");
      // }
    }
  }

  // Highlight the correct link in sidebar when user accesses any link directly
  sidebarTargetConnected() {
    // console.log("sidebar connected", 'xxx' + this.pageValue + 'xxx');
    // Right after login, the pageValue will be empty - make it as dashboard
    // Highlight property link for units
    let pageValue =
      this.pageValue == "/units"
        ? "/properties"
        : this.pageValue == "" || this.pageValue == "/"
        ? "/dashboard"
        : this.pageValue;
    // Find anchor inside sidebar, with href that starts with given page link
    let anchor = $('.sidebar a[href^="' + pageValue + '"]').closest("li");
    // console.log(anchor, anchor.length);
    // If link on sidebar is not clicked then keep existing active link as is
    if (anchor.length > 0) {
      // Link inside sidebar clicked
      // Highlight the found link
      this.highlightLink(anchor);
    }
  }

  // Handle content changed event when "content" turbo_frame is populated with fresh content
  contentChanged(event) {
    // Get the page url for the new content
    let pageUrl = event.detail.pageUrl;
    // If the url is (mostly) e.g. /property/1, discard the id from the pageUrl
    if (pageUrl.split("/").length > 2) {
      pageUrl = pageUrl.split("/").slice(0, 2).join("/");
      // console.log('pageUrl after processing', pageUrl);
    }
    // Highlight property link for units
    if (pageUrl == "/units") pageUrl = "/properties";
    if (pageUrl == "" || pageUrl == "/") pageUrl = "/dashboard";
    // Find anchor inside sidebar, with href that starts with given page link
    let anchor = $('.sidebar a[href^="' + pageUrl + '"]').closest("li");
    // console.log("contentChanged", pageUrl, anchor);
    // Highlight the sidebar link based on this url
    if (anchor.length > 0) {
      this.highlightLink(anchor);
    }
  }

  // Handle click on any sidebar link -- NOT USED
  markActive(event) {
    // Highlight the clicked link
    this.highlightLink(event.currentTarget);
    // console.log("markActive", event.currentTarget);
    // Mark all links as inactive
    // Hide the sidebar for smaller screens
  }

  // Set RHS content left margin based on sidebar width
  setContentMargin() {
    this.width = window.innerWidth;
    // Get sidebar width
    let sidebar_width = $(".sidebar").css("width");
    // console.log("inside set content margin", this.width, sidebar_width);
    // Set content left margin based on window width
    if (this.width < 768) {
      $(".content").css("marginLeft", "70px");
    } else {
      $(".content").css("marginLeft", sidebar_width);
    }
  }

  // Handle click on collapse/expand of sidebar
  showMenu(event) {
    $(".sidebar").toggleClass("close");
    $(".content").toggleClass("sidebar-close");
    if ($(".sidebar").hasClass("close")) {
      $(".parent-item-open-link").attr("data-bs-toggle", "dropdown");
      $(".parent-item-open-link ~ ul").removeClass("sub-menu");
      $(".parent-item-open-link ~ ul").addClass(
        "dropdown-menu dropdown-menu-end"
      );
    } else {
      $(".parent-item-open-link").attr("data-bs-toggle", "");
      $(".parent-item-open-link ~ ul").removeAttr(
        "style",
        "data-popper-placement"
      );
      $(".parent-item-open-link ~ ul").removeClass(
        "dropdown-menu dropdown-menu-end"
      );
      $(".parent-item-open-link ~ ul").addClass("sub-menu");
    }
    // Adjust sidebar and content pane widths
    // Get the chevron icon
    let $icon = $(event.currentTarget).find("i");
    // Toggle the icon
    // $icon.toggleClass("bi-chevron-left bi-chevron-right");
    // Toggle the admin request button elements
    $("#admin-privileges").toggleClass("hidden");
    // Toggle the short text in line with above toggle
    $("#admin-privileges-status").toggleClass("hidden");
    // Toggle items to the center only for larger screens where we show only icons within 90px
    if (this.width > 767) {
      $(".sidebar-item").toggleClass(
        "justify-content-start justify-content-center"
      );
    }
  }

  // Handle submenu show/disable inside sidevar
  showSubMenu(event) {
    event.preventDefault();
    $(event.target).closest("li").toggleClass("showMenu"); //selecting main parent of arrow
  }
}
